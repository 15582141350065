import {HttpService} from '../../services/httpService';
import {Meta} from '@angular/platform-browser';
import {BaseDataResolver, DataModel} from '../../services/base-data-resolver';
import { Injectable, LOCALE_ID, inject } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {PartnersDirectoryService} from '../../services/partnersDirectory';
import {IPagingData} from '../../services/packageTypes';
import {environment} from '../../../environments/environment';
import {OEXMarkdown} from '../../services/markdown';
import {ISiteRelease} from './releases-list';
import {ConfigService} from '../../services/configService';
import {DatePipe} from '@angular/common';

const STORIES_PAGE_SIZE = 30;
const RELEASE_DATE_FORMAT = 'MMMM dd, yyyy';

export class ReleasesListModel extends DataModel implements IPagingData<ISiteRelease> {
  sorting = '';
  searchTerm = '';
  searchCats = '';
  searchTags = '';
  // fltAdmin: AdminFilters;
  tagList: string[] = [];

  currPage = 1
  pageSize = 10
  total = 10;
  items: ISiteRelease[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class ReleasesListResolver extends BaseDataResolver {
  private http = inject(HttpService);
  private route = inject(ActivatedRoute);
  private pd = inject(PartnersDirectoryService);
  private meta = inject(Meta);
  private locale = inject(LOCALE_ID);

  protected override key = 'success-stories';
  protected override model!: ReleasesListModel;
  private isServices = false;
  private datePipe: DatePipe;

  constructor() {
    super();
    const locale = this.locale;

    this.datePipe = new DatePipe(locale);
  }

  static prepareItem(item: ISiteRelease, datePipe: DatePipe) {
    item.descriptionMD = OEXMarkdown.parse(item.description);
    item.date = item.date.split(' ')[0];
    item.dateUI = ReleasesListResolver.convertDate(item.date, datePipe) ?? '';
    if (typeof item.tags === 'string') {
      item.tags = item.tags.split(',');
    }
  }

  /**
   * Converts date to format "20 March, 2020, 19:20:23"
   * @param date
   */
  private static convertDate(date: string, datePipe: DatePipe) {
    const d = new Date(ConfigService.fromServerDate(date));
    return datePipe.transform(d, RELEASE_DATE_FORMAT);
  }

  createModel(): ReleasesListModel {
    return {
      currPage: parseInt(this.route.snapshot.queryParamMap.get('page') || 1 as any, 10),
      pageSize: STORIES_PAGE_SIZE,
      total: 0,
      items: [],
      // fltAdmin: new AdminFilters(),
      searchTerm: '',
      sorting: '',
      searchCats: '',
      searchTags: '',
      tagList: []
    };
  }

  /**
   * Restores admin filtering
   */

  /* restoreAdminFiltering() {
     const af = StorageService.get('adminFiltres');
     if (!af) {
       return;
     }
     try {
       const o = JSON.parse(decodeURIComponent(af));
       if (o) {
         this.model.fltAdmin = o;
       }
     } catch (e) {
       console.error(`Can't restore admin filtering status`);
     }
   }*/

  /**
   * Loads filter parameters from params and query params
   * @param {ActivatedRouteSnapshot} route
   */
  override loadParams(route: ActivatedRouteSnapshot) {
    super.loadParams(route);

    // this.restoreAdminFiltering();

    this.model.searchTerm = route.queryParams.search || '';
    //this.model.searchWorksWith = AppService.decodeText(route.queryParams['work-with'] || '');
    this.model.sorting = route.queryParams.sort || 'd.desc';
    this.model.searchCats = route.queryParams.cat || '';
    this.model.searchTags = route.queryParams.tags || '';

    /*this.model.worksWithItems.forEach((i: any) => i.selected = false);
    if (this.model.searchWorksWith) {
      const ww = this.model.searchWorksWith.split(',');
      this.model.worksWithItems.forEach((i: any) => i.selected = ww.includes(i.text));
    }*/
  }

  /**
   * Request data
   */
  requestData(): Promise<any> {
    // Base rest forms request
    let req;
    let pd = '';
    if (environment.pd) {
      pd = '&isCompany=1';
    }

    const currPage = parseInt(this.route.snapshot.paramMap.get('page') || this.model.currPage as any, 10);

    let url = `/mpapi/site_releases/find_pagination?term=${this.model.searchTerm}&cat=${this.model.searchCats}&tags=${this.model.searchTags}&sorting=${this.model.sorting}`;
    url = url.replace(/é/g, '%C3%A9');

    // Admin filters
    /*
        if (this.auth.isAdmin) {
          url += `&isPublished=${this.model.fltAdmin.isPublished ? 1 : 0}`;
          url += `&isDraft=${this.model.fltAdmin.isDraft ? 1 : 0}`;
          url += `&isWaiting=${this.model.fltAdmin.isWaiting ? 1 : 0}`;
        }
    */

    url += `&pageSize=${STORIES_PAGE_SIZE}&currPage=${currPage}`;
    req = this.http.request(url + pd, 'GET');

    // Request data
    const p1 = new Promise<any>((res: any, rej) => {
      req
        .then(data => {
          this.model.total = data.total;
          this.model.items = data.items || data;
          this.prepareData();
        })
        .catch(d => {
          console.error('Error retrieving list of packages:', d);
        })
        .finally(() => {
          /*  this.model.items = [{
              id: '1',
              category: 'Features',
              description: '## This is a test release\ndescription description description description description description',
              tags: 'tag1, tag2, tag3',
              date: 'November 16, 2021',
              needApprove: 0,
              published: 1
            }];*/
          this.prepareData();

          res();
        });
    });

    const p2 = this.http.request('/mpapi/site_releases/tags')
      .then(d => {
        this.model.tagList = d;
      });

    return Promise.all([p1, p2]);
  }

  /**
   * Updates meta tags with app info
   */
  updateMetaTags() {
    const m = this.model;
    const img = this.pd.enabled ? 'https://partner.intersystems.com/assets/img/logo.svg' : 'https://openexchange.intersystems.com/assets/img/logo.svg';
    this.meta.addTag({name: 'og:title', property: 'og:title', content: (this.pd ? 'Partner Directory' : 'Open Exchange') + ' Releases'});
    this.meta.addTag({
      name: 'og:description',
      property: 'og:description',
      content: 'Releases history on Intersystems ' + (this.pd.enabled ? 'Partner Directory' : 'Open Exchange')
    });
    this.meta.addTag({name: 'og:image', property: 'og:image', content: img});
    this.meta.addTag({name: 'og:image:secure_url', property: 'og:image:secure_url', content: img});
  }

  private prepareData() {
    this.model?.items?.forEach(r => {
      ReleasesListResolver.prepareItem(r, this.datePipe);
    });
  }
}
